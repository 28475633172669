.tool-guided-meditations {

    .meditations-grid {
        margin-top: 48px;
    }

    .meditation-tile {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        background-color: #eee;
        border-radius: 4px;
        padding: 8px;
      
        .meditation-thumbnail {
          margin-right: 10px;
      
          img {
            width: 120px;
            height: auto;
            max-height: 70px;
          }
        }
      
        h2 {
          margin: 0 10px 0 0;
          font-size: 1.2em;
          flex-grow: 1;
        }

        .meditation-duration {
            margin-right: 24px;
        }
      
        .meditation-links {
          display: flex;
          gap: 10px;
      
          a {
            width: 120px;
            height: 50px;
            border-radius: 5px;
            color: white;
            text-decoration: none;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.12s ease-in-out, box-shadow 0.12s ease-in-out;
          }

          a:hover {
            transform: scale(1.1, 1.1);
            box-shadow: 0 2px 8px #0002;
          }
      
          .youtube-button {
            background-color: darkred;
          }
      
          .download-button {
            background-color: blue;
          }
      
          .podcast-button {
            background-color: green;
          }
        }
    }

    .music-icon {
        display: inline-block;
        width: 16px;
    }
}