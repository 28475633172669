.tool-reward-randomizer {
    text-align: center;
  
    input {
      width: 400px;
      margin-right: 10px;
    }
  
    .input-group {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  
    button {
          margin: 5px;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
        }
      
        textarea {
          width: 100%;
          height: 200px;
          margin-bottom: 10px;
        }
      
        .initial-message-container {
          transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        }
        
        .initial-message-container.fade-out {
          opacity: 0;
          transform: translateY(0px);
        }
      
        .results {
          font-size: 24px;
          margin-top: 20px;
          text-align: center;
      
          .fade-in {
            opacity: 0;
            animation: fadeIn 0.5s forwards;
          }
      
          .reward-item {
            border: 1px solid #ccc;
            border-radius: 8px;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 10px;
            margin: 10px auto;
            width: 50%;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
          }
      
          .reward-item.show {
            opacity: 1;
          }
        }
      
        @keyframes fadeIn {
          to {
            opacity: 1;
          }
        }
      
        .back-button {
          position: fixed;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
      
        .back-button.show {
          opacity: 1;
        }
  }