.tool-pairup {
    padding: 20px;
    font-family: Arial, sans-serif;
  
    h2 {
      color: #333;
      text-align: center;
    }
  
    h3 {
      margin-top: 20px;
      color: #555;
    }
  
    #tool-pairup-add-person,
    button {
      margin-top: 10px;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #28a745;
      color: #fff;
      cursor: pointer;
  
      &:hover {
        opacity: 0.9;
      }
    }
  
    .people-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
  
      .people-name {
        flex: 1;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      .people-available {
        margin-left: 10px;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: #fff;
        cursor: pointer;
        width: 100px;
  
        &.inactive {
          background-color: #6c757d;
        }
  
        &:hover {
          opacity: 0.9;
        }
      }
    }
  
    .sessions {
      margin-top: 30px;
      font-size: 125%;
  
      div {
        border: 1px solid #ddd;
        padding: 15px;
        border-radius: 4px;
        margin-bottom: 15px;
  
        h4 {
          margin-bottom: 20px;
          margin-top: 0px;
          color: #444;
        }
  
        p {
          margin: 5px 0;
          color: #666;
        }
  
        button {
          background-color: #dc3545;
          margin-left: 16px;
          padding: 4px 8px;
          opacity: 0.15;
  
          &:hover {
            opacity: 1;
          }
        }

        .group-id {
            font-weight: bold;
            display: inline-block;
            margin-right: 24px;
            margin-bottom: 12px;
        }
      }
    }

    #new-session-button {
        margin: 32px auto;
        display: block;
    }

    #tool-pairup-clear-all {
      float: right;
      background-color: red;
    }
}