
.app-header {
    width: 100%;
    background-color: #333;
    color: white;
    height: 64px;
    margin: 0;
    padding: 10px;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 

    header {
        display: flex;
    }

    h1 {
        padding: 0;
        margin: 0px;
        display: inline-block;
    }

    .space {
        display: inline-block;
        flex-grow: 1;
    }

    nav {
        display: inline-block;
        padding-right: 20px;
        button {
            font-size: 120%;
            border: none;
            background-color: transparent;
            padding: 0 12px;
            margin-top: 12px;
        }
    }

    a {
        color: white;
        text-decoration: none;
        &:hover {
            color: #ddf;
        }
    }
}