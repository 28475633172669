
.app-footer {
    width: 100%;
    background-color: #333;
    color: white;
    height: 40px;
    position: absolute;
    margin: 0;
    padding: 8px;
    text-align: center;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 

    a {
        color: white;
        text-decoration: none;
        margin: 0 32px;
        &:hover {
            color: #ddf;
        }
    }
}