html {
  background-color: #333;
}

body {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.impressum {
  pointer-events: none;
}

.jk {
  position: fixed;
  left: 200vw;
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.App-link {
  color: #61dafb;
}

.page-body {
  width: 100%;
  background-color: #e0e0e0;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

.page-content {
  max-width: 1000px;
  margin: auto;
  background-color: white;
  min-height: calc(100vh - 106px);
  padding: 32px;
  box-shadow: 0 0 16px #0001;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 

  h2 {
    margin: 24px 0 12px 0;
  }
}