.tool-fermi-assistant {
    text-align: center;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 30px auto;
  
    h1 {
      font-size: 28px;
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;
    }
  
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #555;
      margin-bottom: 15px;
  
      input[type="checkbox"] {
        margin-right: 8px;
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  
    input[type="text"],
    input[type="password"],
    textarea {
      width: 100%;
      max-width: 600px;
      margin-bottom: 15px;
      padding: 12px;
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 6px;
      outline: none;
      transition: all 0.3s ease;
  
      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
      }
  
      &::placeholder {
        color: #aaa;
      }
    }

    textarea {
      min-height: 200px;
    }

    p {
      margin: 25px 85px;
    }

    .result-num {
      font-weight: bold;
      color: blue;
    }

    .info-text {
      font-size: 12px;
      color: #777;
    }
  
    button {
      display: block;
      padding: 12px 25px;
      font-size: 16px;
      font-weight: bold;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;
      margin: 15px auto 0;
  
      &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
      }
  
      &:disabled {
        cursor: not-allowed;
        background-color: #ccc;
      }
    }
  
    .loading {
      margin-top: 20px;
      font-size: 18px;
      color: #555;
    }
  
    .results {
      margin-top: 30px;
      text-align: left;
      background-color: #fff;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  
      h2 {
        font-size: 20px;
        color: #333;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        margin-bottom: 15px;
      }
  
      .result-item {
        border: 1px solid #eee;
        border-radius: 8px;
        background-color: #f9f9f9;
        padding: 15px;
        margin-bottom: 15px;
        transition: transform 0.3s ease;
  
        &:hover {
          // transform: translateY(-3px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        }
  
        strong {
          font-weight: bold;
          color: #444;
        }
  
        .collapsible {
          cursor: pointer;
          color: #007bff;
          text-decoration: underline;
          font-size: 14px;
  
          &:hover {
            color: #0056b3;
          }
        }
  
        div {
          margin-top: 10px;
          font-size: 14px;
          color: #666;
        }
      }
  
      p,
      ul {
        font-size: 16px;
        color: #555;
        margin: 10px 0;
      }
  
      ul {
        padding-left: 20px;
  
        li {
          margin-bottom: 8px;
        }
      }
    }
  }
  