
.page-tools {

    a {
        text-decoration: none;
    }

    .tools-grid {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        padding: 40px;
    }

    .tool-tile {
        margin: 20px;
        width: 232px;
        height: 160px;
        border: 1px solid #aaf;
        border-radius: 8px;
        text-align: center;
        color: black;
        box-shadow: 0 0px 24px #0000;
        background-color: #ddf;
        text-decoration: none;
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        padding: 4px;
        overflow: hidden;

        p {
            width: 90%;
            left: 5%;
            font-size: 80%;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            color: #333;
            position: absolute;
            top: 40px;
        }

        img {
            max-width: 80%;
            max-height: 50%;
            opacity: .75;
            transition: opacity 0.2s ease-in-out;
        }

        &:hover {
            transform: scale(1.1, 1.1);
            box-shadow: 0 2px 8px #0002;

            p {
                opacity: 1;
            }

            img {
                opacity: 0.15;
            }
        }

    }

}